<template>
  <v-text-field
    disabled
    dense
    class="disabled-text-input"
    outlined
    :value="value"
  />
</template>

<script>
export default {
  name: "DisabledTextInput",
  props: {
    value: {
      default: () => null,
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled-text-input {
  .v-text-field__details {
    display: none;
  }
  .v-input__control {
    background: #e5eaed;

    .v-input__slot {
      margin: 0;
    }
  }
  fieldset {
    border: solid 1px #dee7ee;
  }
  input {
    // font-family: PingFangSC;
    font-size: 13px;
    // letter-spacing: 1px;
    color: #66788e;
  }
}
</style>
